// import logo from './logo.svg';
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

import './App.css';

// api call
let wikiquery = "https://en.wikipedia.org/w/api.php?action=query&generator=random&grnnamespace=0&grnlimit=100&prop=pageimages|info|extracts&exintro&explaintext&piprop=original&inprop=url&pilicense=any&format=json&origin=*";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      choices: [],
      history: [],
      showHistoryModal: false,
      showAboutModal: false
    }

    // bind click actions
    this.selectChoice = this.selectChoice.bind(this);
    this.handleShowHistory = this.handleShowHistory.bind(this);
    this.handleCloseHistory = this.handleCloseHistory.bind(this);

    this.handleShowAbout = this.handleShowAbout.bind(this);
    this.handleCloseAbout = this.handleCloseAbout.bind(this);
  }

  getNewChoice(e) {
    fetch(wikiquery)
      .then(res => res.json())
      .then(
        (result) => {
          // console.log('Result', result); 
          // console.log('Pages', pages); 

          let count = 1,
              pages = result.query.pages,
              choices = [];

          for (const page in pages) {
            // Only use choices with an image 
            if (pages[page].original) {
              if (count === 3) { break; } // break loop when done

              // store relevant choice info
              let choice = {
                title: pages[page].title,
                url: pages[page].fullurl,
                img: pages[page].original.source,
                pageid: pages[page].pageid
              };

              choices.push(choice);

              // add to local storage
              localStorage.setItem('ch_choices', JSON.stringify(choices));
          
              // increase count
              count++;
            }
          }

          // set state of built choices array
          this.setState({
            choices: choices
          });

          document.title = choices[0].title + ' or ' + choices[1].title + '?';
        },
        (error) => {
          // do nothing.
        }
      )
  }

  getCurrentChoice(e) {
    let currentChoices = JSON.parse(localStorage.getItem("ch_choices"));

    console.log(currentChoices);

    this.setState({
      choices: currentChoices
    });

    document.title = currentChoices[0].title + ' or ' + currentChoices[1].title + '?';
  }

  selectChoice(e) {
    let choiceIndex = e.target.getAttribute("data-index");
    e.preventDefault();
    // Update history with the choice made
    this.updateHistory(choiceIndex); // what the user picked
    this.setState({
      choices: []
    });
    // get new choices
    this.getNewChoice();
  }

  getHistory(e) {
    if (localStorage.getItem("ch_history") === null) {
      console.log('no history');
      // create choices array   
      localStorage.setItem("ch_history", JSON.stringify(this.state.history));

      this.setState({
        showAboutModal: true,
      })
    } else {
      console.log('has history');
      // set choices array 
      let storedHistory = JSON.parse(localStorage.getItem("ch_history"));

      this.setState({
        history: storedHistory
      });

      // always show abuot until first choice is made
      if (storedHistory.length === 0) {
        this.setState({
          showAboutModal: true,
        });
      }
    }
  }

  updateHistory(choiceIndex) {
    let noIndex = 0,
        tempHistory = this.state.history;

    (choiceIndex === '0') ? noIndex = 1 : noIndex = 0;

    let historyItem = {yes: this.state.choices[choiceIndex], no: this.state.choices[noIndex]}

    // console.log(choiceArray);
    tempHistory.push(historyItem);

    // set state
    this.setState({
      history: tempHistory
    });

    // set localstorage
    localStorage.setItem("ch_history", JSON.stringify(tempHistory));
  }

  componentDidMount() {
    this.getHistory();

    if (localStorage.getItem("ch_choices") != null) {
      this.getCurrentChoice();
    } else {
      this.getNewChoice();
    }
  }

  handleShowHistory() {
    this.setState({
      showHistoryModal: true,
      showAboutModal: false
    });
  }

  handleCloseHistory() {
    this.setState({
      showHistoryModal: false
    });
  }

  handleShowAbout() {
    this.setState({
      showAboutModal: true,
      showHistoryModal: false
    });
  }

  handleCloseAbout() {
    this.setState({
      showAboutModal: false
    });
  }

  render() {
    // console.log('State Check', this.state);

    const { choices, history, showHistoryModal, showAboutModal } = this.state;

    return (
      <div className="app">

        <div className="app__content">
          <div className="gameboard">
            {choices.map((item, index) => (
              <div className="gameboard__item" key={index}>
                <div className="card">
                  <a className="card-img" href={item.url} target="_blank" rel="noreferrer" style={{backgroundImage : 'url(' + item.img + ')'}}><span>Learn More</span></a>
                  <div className="card-body">
                    { /*<h5 className="card-title"><a href={item.url} className="" target="_blank" rel="noreferrer">{item.title}</a></h5> */ }
                     {/* <button className="btn btn-success" data-index={index} onClick={this.selectChoice}>Pick {item.title}</button> */}
                   <Button variant="success"  onClick={this.selectChoice} data-index={index}>
                      {item.title}
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="app__header">
          <Button variant="secondary" size="sm" onClick={this.handleShowHistory}>
            View History
          </Button>
        </div>

        <Modal centered scrollable size="lg" show={showHistoryModal} onHide={this.handleCloseHistory}>
          <Modal.Header closeButton>
            <Modal.Title>History</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered size="sm">
              <tbody>
                {history.slice(0).reverse().map((item, index) => (
                  <tr key={index}>
                    <td>You chose <a href={item.yes.url} target="_blank" rel="noreferrer" className="link-secondary">{item.yes.title}</a> over <a href={item.no.url} target="_blank" rel="noreferrer" className="link-secondary">{item.no.title}</a>.</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseHistory}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleShowAbout}>
              About the Project
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal centered scrollable show={showAboutModal} onHide={this.handleCloseAbout}>
          <Modal.Header closeButton>
            <Modal.Title>About Choices</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>A simple game where you are presented with two choices. After you pick, you are given two more. And so on... Look back on your past choices, and learn from them.</p>
            <p>Click the image to learn more about that choice. Press the green button to make your decision.</p>
            <p>Play with friends!</p>
            <p>Build with React and <a href="https://www.mediawiki.org/wiki/API:Main_page" target="_blank">MediaWiki Action API</a>.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseAbout}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleCloseAbout}>
              Play
            </Button>
          </Modal.Footer>
        </Modal>



      </div>
    );
  }
}

export default App;




